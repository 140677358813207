import React from 'react';
import './scss/app.scss';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navigation from './components/Navigation';
import Home from './views/Home';
import NotFound from './views/NotFound';
import Footer from './components/Footer';
import Confidentiality from './views/Confidentiality';

function App() {
	return (
		<Router>
			<Navigation />
			<Routes>
				<Route index element={<Home />} />
				<Route path='/confidentiality' element={<Confidentiality />} />
				<Route path='*' element={<NotFound />} />
			</Routes>
			<Footer />
		</Router>
	);
}

export default App;
