import { Link } from 'react-router-dom';
import NavbarLogo from '../assets/logo-BA-blanc.svg';

const Navigation: React.FC = () => {
	return (
		<nav className='navbar'>
			<div className='container'>
				<div className='row'>
					<h1 className='navbar-logo'>
						<img alt='Body Analyser' src={NavbarLogo} />
					</h1>
					<ul className='navbar-items'>
						<li className='navbar-item'>
							<Link to='/'>Accueil</Link>
						</li>
					</ul>
				</div>
			</div>
		</nav>
	);
};

export default Navigation;
