const NotFound: React.FC = () => {
	return (
		<section className='page page-not-found'>
			<div className='container'>
				<div className='row'>
					<div className='col-xs-12'>
						<h1>404</h1>
						<p>Page not found</p>
					</div>
				</div>
			</div>
		</section>
	);
};

export default NotFound;
