import Hero from '../components/Hero';
import background from '../assets/images/background.jpg';

const Home: React.FC = () => {
	return (
		<section className='page page-home'>
			<Hero image={background}>
				<h1>Body Analyser</h1>
				<h2>Connaître son corps, ses besoins et fixer ses objectifs</h2>
			</Hero>
			<section>
				<div className='container'>
					<div className='row'>
						<div className='col-xs-12'>
							<h2>Qu'est-ce que Body Analyser ?</h2>
							<p>
								Le Body Analyser est un bilan corporel réalisé au sein d'un BeBô Hub, vous permettant de découvrir votre masse grasse, hydrique et maigre à
								l'aide de quelques mesures.
							</p>
						</div>
					</div>
				</div>
			</section>
			<section>
				<div className='container'>
					<div className='row'>
						<div className='col-xs-12'>
							<h2>Où trouver le Body Analyser ?</h2>
							<p>Retrouvez notre Body Analyser dans l'un des BeBô Hub</p>
							<a href='https://be-bo.eu/hub' target='_blank'>
								En savoir plus
							</a>
						</div>
					</div>
				</div>
			</section>
		</section>
	);
};

export default Home;
