interface HeroProps {
	children?: React.ReactNode;
	image?: string;
}

const Hero = (props: HeroProps) => {
	return (
		<section className='hero'>
			<div className='container'>
				<div className='row'>
					<div className='col-xs-12 hero-content'>{props.children}</div>
				</div>
			</div>
			{props.image && (
				<div className='hero-background'>
					<img src={props.image} />
				</div>
			)}
		</section>
	);
};

export default Hero;
