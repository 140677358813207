import { Link } from 'react-router-dom';
import Logo from '../assets/logo-BA-blanc.svg';

const Footer: React.FC = () => {
	return (
		<footer className='footer'>
			<div className='container'>
				<div className='row'>
					<div className='col-xs-12 col-md-4'>
						<ul className='footer-nav'>
							<li className='footer-nav-item'>
								<Link to='/confidentiality'>Confidentialité</Link>
							</li>
							<li className='footer-nav-item'>
								<a href='https://be-bo.eu/hub' target='_blank'>
									BeBô Hub
								</a>
							</li>
						</ul>
					</div>
					<div className='col-xs-12 col-md-4'></div>
					<div className='col-xs-12 col-md-4'>
						<img alt='Body Analyser' src={Logo} />
					</div>
				</div>
				<p className='footer-copy'>&copy; {new Date().getFullYear()} - CES Management</p>
			</div>
		</footer>
	);
};

export default Footer;
